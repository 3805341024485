<template>
    <div>
        <modal name="respostaModal" height="auto" width="55%" :scrollable="true" :clickToClose="true">
        <CloseModal :close="close" />
        <div class="modalbox">
            <div class="modalbox-content">
                <CTabs variant="tabs" class="nav-tabs-boxed">
                    <CTab :title="`${ formulario.titulo  }`">
                        <div class="xrow" v-for="pergunta in perguntas">
                            <div class="col-1-1">
                                <div class="form-group">
                                    <label for="">{{pergunta.descricao}}</label>           
                                        <RespostaView 
                                            :tipo="pergunta.tipo"
                                            :alternativas="pergunta.alternativas"
                                        />
                                    <br >
                                </div>
                            </div>
                        </div>
                       
                        <div class="xrow edit-buttons">
                            <button class="button pull-right" v-on:click="close">Fechar</button>
                        </div>
                    </CTab>
                </CTabs>
            </div>
        </div>
        </modal>
    </div>
</template>
<script>
import CloseModal from "../../components/CloseModal";
import RespostaView from "./components/RespostaView";
  export default {
    name: 'respostaModal',
    components: { CloseModal, RespostaView },
    data() {
        return { 
            currentItem: {},
            loading: false,
            currentPage: 1,
            items: [],
            table: {
                filter: "",
                columns: [
                    { label: "ID", field: "id",  align: "left", sortable: false },
                    { label: "Título", field: "titulo", sortable: false },
                    { label: "Hash", field: "data", sortable: false },
                ],
                paginator: {},
            },
            descPlural: '',
            descSingular: this.formulario.titulo,
        }
    },
    methods: { 
       closeEdit(){
            this.$modal.hide("respostaModal");
        }
    },    
    props: ['formulario','close','perguntas'],
  };

</script>