<template>
  <div>
    <div v-for="alternativa in alternativas" >
          <div v-if="tipo == 'TEXT' || tipo == 'TEXTAREA' ">
            <div v-if="alternativa.length > 2 ">
                <img class="img-thumbnail" style="background-color: green;"> {{alternativa}} 
            </div>
            <div v-else-if="alternativa.active == false">
              <img class="img-thumbnail" style="background-color: gray;"> Não informado.
            </div>
            <div v-else-if="alternativa.length">
              <img class="img-thumbnail" style="background-color: gray;"> Não informado.
            </div>
           
        </div>
      

        <div v-if="tipo == 'RADIO' || tipo == 'CHECKBOX' ">
            <div v-if="alternativa.active == true">
                <img class="img-thumbnail" style="background-color: green; .img-thumbnail"> {{alternativa.descricao}} 
            </div>
            <div v-else-if="alternativa.active == false">
                <img class="img-thumbnail" style="background-color: gray;">  {{alternativa.descricao}}
            </div>
        </div>
  
    </div>
  </div>
</template>

<script>
  export default {
    
    name: 'respostaView',
    components: { },
    data() {
        return { 
            
         };
    },
    methods: {
       
    },    
    props: ['tipo','alternativas'],
  };

</script>
