<template>
  <modal
    name="formularioEdit"
    height="auto"
    width="90%"
    :scrollable="true"
    :clickToClose="false"
  >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="`Cabeçalho do Formulário`">
            <div class="xrow">
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Título</label>
                  <input class="form-control" v-model="formulario.titulo" />
                </div>
              </div>

              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Slug</label>
                  <input class="form-control" v-model="formulario.slug" />
                </div>
              </div>

              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Tipo</label>
                  <input
                    class="form-control"
                    disabled
                    v-model="formulario.tipo"
                  />
                </div>
              </div>

              <div class="col-1-1">
                <div class="form-group">
                  <label for="">
                    Conteúdo:
                    <button class="btn btn-primary" v-on:click="code = !code">
                      {{ !code ? "Código fonte" : "Editor" }}
                    </button></label
                  >
                  <div v-if="!code">
                    <vue-editor
                      class="vue2-editor"
                      v-model="formulario.descricao"
                      :editor-toolbar="customToolbar"
                    ></vue-editor>
                  </div>
                  <div v-if="code">
                    <div class="codemirror">
                      <codemirror
                        v-show="true"
                        v-model="formulario.descricao"
                        :options="code_options"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div class="col-1-1">
                <button
                  type="button"
                  v-on:click="handleSubmit"
                  class="button button-success pull-right"
                >
                  <strong>Salvar</strong>
                </button>
              </div>
            </div>
          </CTab>
          <CTab :title="`Perguntas`">
            <div class="xrow">
              <Perguntas
                :perguntas="formulario.perguntas"
                :formulario="formulario"
              />
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer"></div>
  </modal>
</template>

<script>
import CloseModal from "../../../components/CloseModal";
import Perguntas from "../Perguntas.vue";
import { VueEditor } from "vue2-editor";
import { get, post, put, del } from "@/helpers/apiRequest";

import { codemirror } from "vue-codemirror";
// theme css
import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";
import "codemirror/theme/zenburn.css";
// language js
import "codemirror/addon/selection/active-line.js";
import "codemirror/addon/display/autorefresh.js";
import "codemirror/mode/sql/sql.js";
// autoCloseTags
import "codemirror/addon/edit/closetag.js";

export default {
  name: "formularioEdit",
  components: {
    CloseModal,
    VueEditor,
    Perguntas,
    codemirror,
  },
  data() {
    return {
      code: false,
      perguntas: {},
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link", "image", "video", "code"],
        ["clean"],
      ],
    };
  },
  props: ["close", "formulario", "refresh"],
  methods: {
    handleSubmit() {
      const request = post("/admin/formularios/formulario", this.formulario);
      request
        .then((success) => {
          this.$swal({
            title: "Sucesso!",
            text: "Pergunta cadastrada com sucesso!",
            icon: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
        });

      return request;
    },
  },
  computed: {
    code_options() {
      return {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "htmlmixed",
        autoRefresh: false,
        lineWrapping: true,
        readOnly: false,

        theme: "monokai",
        showCursorWhenSelecting: true,
        highlightSelectionMatches: {
          showToken: /\w/,
          annotateScrollbar: true,
        },
      };
    },
  },
};
</script>
