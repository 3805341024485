<template>
  <div>
    <modal name="perguntaEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
      <CloseModal :close="close" />
      <div class="modalbox">
        <div class="modalbox-content">
            <CTabs variant="tabs" class="nav-tabs-boxed">
            
                <CTab :title="`Editando Pergunta`">
                
                    <div class="xrow">
                        <div class="col-1-1">
                            <div class="form-group">
                            <label for="">Descrição</label>                
                            <input class="form-control" v-model="item.descricao"/>
                            </div>
                        </div>
                    </div>

                    <div class="xrow">
                        <div class="col-1-3">
                            <div class="form-group">
                                <label for="">Tipo</label> 
                                <select name="perguntaTipo" id="perguntaTipo" class="form-control" v-model="item.tipo" @change="onChange($event)">
                                  <option value="TEXT">Text</option>
                                  <option value="RADIO" selected>Radio</option>
                                  <option value="CHECKBOX">Checkbox</option>
                                  <option value="TEXTAREA">Textarea</option>
                                </select>               
                            </div>
                        </div>
                    </div>

                    <div class="xrow">
                        <div class="col-1-1">
                            <div class="form-group">
                              <Alternativas 
                                  :close="close"
                                  :pergunta="item"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="xrow edit-buttons">
                        <button :class="{ 'button button-success': true }" v-on:click="handleSubmit">
                        Salvar
                        </button>
                        &nbsp;
                        <button class="button" v-on:click="close">Fechar</button>
                    </div>

                </CTab>
                
            </CTabs>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import CloseModal from "../../../components/CloseModal";
  import { get, post, put, del } from '@/helpers/apiRequest';
  import Alternativas from "./Alternativas.vue";

  export default {
    name: 'perguntaEdit',
    props: [ 'item', 'close','formulario'],
    components: { CloseModal, Alternativas },
    data() {
      return {
        
      }
    },
    computed: {},
    mounted() {},
    validators: {},
    methods: {
        handleSubmit() {
          if ( typeof this.item.id === 'undefined' ){
            this.item.formulario_id = this.formulario.id;
          }
          const request = post('/admin/formularios/pergunta', this.item);
          request
            .then((success) => {
              this.$swal({
                title: 'Sucesso!',
                text: 'Pergunta cadastrada com sucesso!',
                icon: 'success',
              });
            })
            .catch((err) => {
              this.loading = false;
            });
            this.closeModal();
          return request
        },
        onChange(event) {
          this.item.tipo = event.target.value; 
          this.item.alternativas = [];
        },
        closeModal() {
            this.close();
        },

    }
  }
</script>