<template>
  <div>
    <div class="content">
        <div class="content-wrapper">
          
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      
                      <th>Título</th>
                      <th class="actions" v-if="pergunta.tipo === 'RADIO' || pergunta.tipo === 'CHECKBOX'">
                        <button type="button" class="button button-success" v-on:click="addOption( pergunta.alternativas )">
                            <fa-icon icon="plus" />
                        </button>
                      </th>
                      <th class="actions" v-if="pergunta.tipo === 'TEXT' || pergunta.tipo === 'TEXTAREA'">
                        <button type="button" class="button button-success" disabled >
                            <fa-icon icon="plus" />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr v-if="pergunta.tipo === 'TEXT'"  >
                        <td></td>
                        <td>
                          <span class="form-group">
                            <input class="form-control" disabled/> 
                          </span>
                        </td>
                        <td class="actions">
                            <button type="button" class="button button-error" disabled>
                                <fa-icon icon="trash-alt" />
                            </button>
                        </td>
                    </tr>

                    <tr v-else-if="pergunta.tipo === 'TEXTAREA'" >
                       <td></td>
                        <td>
                          <span class="form-group">
                            <textarea class="form-control" disabled> </textarea> 
                          </span>
                        </td>
                        <td class="actions">
                            <button type="button" class="button button-error" disabled>
                                <fa-icon icon="trash-alt" />
                            </button>  
                        </td>
                    </tr>

                    <tr v-else-if="pergunta.tipo === 'RADIO' " v-for="(alternativa, index) in pergunta.alternativas">
          
                      <td>
                            <span class="form-group">
                                <input class="form-control" v-model="alternativa.descricao" />
                            </span>
                      </td>
                      <td class="actions">
                          <button type="button" class="button button-error" v-on:click="removeOption(index, pergunta.alternativas)">
                              <fa-icon icon="trash-alt" />
                          </button>
                      </td>
                    </tr> 
                    
                    <tr v-else-if="pergunta.tipo === 'CHECKBOX'" v-for="(alternativa, index) in pergunta.alternativas" >
                
                      <td>
                            <span class="form-group">
                                <input class="form-control" v-model="alternativa.descricao" />
                            </span>
                      </td>
                      <td class="actions">
                          <button type="button"  class="button button-error" v-on:click="removeOption(index, pergunta.alternativas)">
                              <fa-icon icon="trash-alt" />
                          </button>
                      </td>
                    </tr>
              
                  </tbody>
                </table>
              </div>
        </div>
      </div>
  </div>
</template>

<script>
  export default {
    setup() {},
    emits:['alternativa '],
    name: 'alternativas',
    components: { },
    data() {
        return { 
            item : this.pergunta.alternativas
         };
    },
    methods: {
        addOption(item){
          item.push({ descricao:'',ordem : item.length  });
          this.$forceUpdate(); 
        },
        removeOption(row, alternativas){
      
          alternativas.splice(row, 1); 
          this.$forceUpdate();
        }
    },    
    props: ['pergunta'],
  };

</script>
